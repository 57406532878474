import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../api";

export const getDish = createAsyncThunk("dish/getDish", async (id) => {
  const response = await api().dish.getDish(id);
  return response.data;
});

export const dishSlice = createSlice({
  name: "dish",
  initialState: { data: {}, loading: false, error: null },
  reducers: {},
  extraReducers: {
    [getDish.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [getDish.pending]: (state) => {
      state.loading = true;
    },
    [getDish.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default dishSlice.reducer;
