import API from "./base/api";

import { SERVER } from "../constants/config";

class SettingsApi extends API {
  async getSettings() {
    return this.r({
      method: "GET",
      url: `${SERVER}/settings`,
    });
  }
}

export default function settingsApi(request) {
  return new SettingsApi(request);
}
