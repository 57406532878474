import React from "react";

import { GiPayMoney } from "react-icons/gi";
import { IoIosCash } from "react-icons/io";
import { BsCreditCard } from "react-icons/bs";

import styles from "./styles.module.scss";

const Payment = ({ t, paymentArray, payment, setPayment }) => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>
        {t("pages.cart.components.payment.method")}
      </p>
      <ul className={styles.payMethod}>
        <li
          className={
            paymentArray && paymentArray[0].name === payment && styles.current
          }
          onClick={() => setPayment(paymentArray[0].name)}
        >
          <GiPayMoney className={styles.iconPay} />
          <span>{t("pages.cart.components.payment.paymentByCash")}</span>
        </li>
        <li
          className={
            paymentArray && paymentArray[1].name === payment && styles.current
          }
          onClick={() => setPayment(paymentArray[1].name)}
        >
          <IoIosCash className={styles.iconPay} />
          <span>{t("pages.cart.components.payment.paymentByCart")}</span>
        </li>
        {paymentArray && paymentArray[2] && (
          <li
            className={
              paymentArray && paymentArray[2].name === payment && styles.current
            }
            onClick={() => setPayment(paymentArray[2].name)}
          >
            <BsCreditCard className={styles.iconPay} />
            <span>{t("pages.cart.components.payment.paymentByOnline")}</span>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Payment;
