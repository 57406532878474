import API from "./base/api";
import {
  IIKO_SERVER,
  IIKO_USER_ID,
  IIKO_USER_SECRET,
} from "../constants/config";

class IikoApi extends API {
  async authIiko() {
    return this.r({
      method: "GET",
      url: `${IIKO_SERVER}/api/0/auth/access_token?user_id=${IIKO_USER_ID}&user_secret=${IIKO_USER_SECRET}`,
    });
  }
  async getUser(token, phone) {
    return this.r({
      method: "GET",
      url: `${IIKO_SERVER}/api/0/customers/get_customer_by_phone?access_token=${token}&organization=4b57f51e-dae2-11ea-aa5d-0025906bfe47&phone=${phone}`,
    });
  }
}

export default function iikoApi(request) {
  return new IikoApi(request);
}
