import { sumAmount } from "./item";
import { calcBonus } from "./calculator";
import { TYPE_DISHES } from "../constants/data";

export const emailTemplate = (data) => {
  const date = new Date();
  const stringOrders = data.orders.map((order) => {
    if (order.type === TYPE_DISHES.half) {
      return `Тип: 'Половинки', Название: ${order.name}, Бортик: ${order.board}; Количество: ${order.count} <br />`;
    } else if (order.type === TYPE_DISHES.combo) {
      return `Тип: 'Комбо'; Название: ${order.name}; Описание:  ${order.description} Количество: ${order.count} <br />`;
    } else {
      return `Тип: 'Блюдо'; Название: ${order.name}; Описание:  ${order.description} Количество: ${order.count} <br />`;
    }
  });

  const order_id = data.orderId;
  const name = data.name;
  const phone = data.phone1.length >= 18 ? data.phone1 : data.phone2;
  const address =
    data.deliveryMethod === "delivery"
      ? data.addressDelivery
      : data.addressPickUp;
  const order = JSON.stringify(stringOrders);
  const cutlery = data.isCutlery;
  const price = sumAmount(data.orders);
  const bonus = data.bonus ? calcBonus(data.totalProductsCost, data.iiko) : 0;
  const cost = data.cost;
  const time = `${date.getHours()}:${date.getMinutes()}`;
  const delivery = data.delivery;
  const cost_delivery = data.deliveryPrice;
  const payment = data.payment;
  const comment = data.comment;
  const delivery_time = data.time.length === 0 ? "На сейчас" : data.time;
  const online_payment = data.online_payment === "paymentByOnline" ? true : "-";
  const platform = "Веб";

  const color1 = "#f2f2f2";
  const color2 = "#ffffff";

  const my_html = `
  <div>
    <h2 style="font-size: 22px" >Заказ №: ${order_id}, время: ${time} </h2>
    <h3>Информация о клиенте</h3>
    <table width="75%" cellspacing="0" cellpadding="5" border='0' style="font-size: 14px">
      <tr>
          <td style="background-color:${color1}; padding: 12px;" width="160"><strong>Имя:</strong></td>
          <td style="background-color:${color1}; padding: 12px;" width="340">${name}</td>
      </tr>
      <tr>
          <td style="background-color:${color2}; padding: 12px;" width="160"><strong>Телефон:</strong></td>
          <td style="background-color:${color2}; padding: 12px;" width="340">${phone}</td>
      </tr>
      <tr>
          <td style="background-color:${color1}; padding: 12px;" width="160"><strong>Адрес:</strong></td>
          <td style="background-color:${color1}; padding: 12px;" width="340">${address}</td>
      </tr>
      <tr>
          <td style="background-color:${color2}; padding: 12px;" width="130"><strong>Платформа:</strong></td>
          <td style="background-color:${color2}; padding: 12px;" width="370">${platform}</td>
      </tr>
    </table>
    <br>
    <h3>Информация о зазказе</h3>
    <table width="75%" cellspacing="0" cellpadding="4" border='0' style="font-size: 14px">
      ${data.orders
        .map((item, index) => {
          return `
            <tr>
              <td width="50" align="center" style="${
                index % 2
                  ? `background-color:${color2};`
                  : `background-color:${color1};`
              }">
                <img src=${
                  item.image
                } width="49" height="49" border="0" align="center" style="width:49px; height:49px;" />
              </td>
              <td style="${
                index % 2
                  ? `background-color:${color2};`
                  : `background-color:${color1};`
              }" width="350">${item.name}, ${item.description}</td>
              <td style="${
                index % 2
                  ? `background-color:${color2};`
                  : `background-color:${color1};`
              }" align="center" width="50">${item.count}</td>
              <td style="${
                index % 2
                  ? `background-color:${color2};`
                  : `background-color:${color1};`
              }" align="center" width="50">${item.price}₴</td>
            </tr>
          `;
        })
        .join("")}
        <tr>
          <td align="center" width="50"></td>
          <td align="center" width="300"></td>
          <td width="100"><i>Бонусы:</i></td>
          <td align="center" width="50"><i>${bonus}₴</i></td>
        </tr>
        <tr>
          <td align="center" width="50"></td>
          <td align="center" width="300"></td>
          <td width="100"><i>Доставка:</i></td>
          <td align="center" width="50"><i>${cost_delivery}₴</i></td>
        </tr>
        <tr>
          <td align="center" width="50"></td>
          <td align="center" width="300"></td>
          <td width="100"><strong>К оплате:</strong></td>
          <td align="center" width="50"><strong>${cost}₴</strong></td>
        </tr>
    </table>
    <h3>Информация о доставке и оплате</h3>
    <table width="75%" cellspacing="0" cellpadding="5" border='0' style="font-size: 14px">
      <tr>
          <td style="background-color:${color1}; padding: 12px;" "width="160"><strong>Способ оплаты:</strong></td>
          <td style="background-color:${color1}; padding: 12px;" width="340">${payment}</td>
      </tr>
      <tr>
          <td style="background-color:${color2}; padding: 12px;" "width="160"><strong>Оплачен онлайн:</strong></td>
          <td style="background-color:${color2}; padding: 12px;" width="340" id="paid">${String(
    data.onlinePaid
  )}</td>
      </tr>
      <tr>
          <td style="background-color:${color1}; padding: 12px;" width="160"><strong>Способ доставки:</strong></td>
          <td style="background-color:${color1}; padding: 12px;" width="340">${delivery}</td>
      </tr>
      <tr>
          <td style="background-color:${color2}; padding: 12px;" width="160"><strong>Время доставки:</strong></td>
          <td style="background-color:${color2}; padding: 12px;" width="340">${delivery_time}</td>
      </tr>
      <tr>
          <td style="background-color:${color1}; padding: 12px;" width="160"><strong>Приборы:</strong></td>
          <td style="background-color:${color1}; padding: 12px;" width="340">${cutlery}</td>
      </tr>
       <tr>
      <tr>
        <td style="background-color:${color2}; padding: 12px;" width="160"><strong>Коментерий:</strong></td>
        <td style="background-color:${color2}; padding: 12px;" width="340">${comment}</td>
      </tr>
    </table>
    <br>
    <br>
    <br>
    <div style="color:#808080; foont-size:10px">Tech info: ${
      navigator.userAgent
    }</div>
  </div>
  `;

  return {
    order_id,
    name,
    phone,
    address,
    order,
    price,
    cutlery,
    bonus,
    cost,
    time,
    delivery,
    cost_delivery,
    payment,
    comment,
    delivery_time,
    online_payment,
    platform,
    my_html,
  };
};
