import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../api";

export const getCombos = createAsyncThunk("combos/getCombos", async () => {
  const response = await api().combos.getCombos();
  return response.data;
});

export const combosSlice = createSlice({
  name: "combos",
  initialState: { data: [], loading: false, error: null },
  reducers: {},
  extraReducers: {
    [getCombos.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [getCombos.pending]: (state) => {
      state.loading = true;
    },
    [getCombos.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default combosSlice.reducer;
