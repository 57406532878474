import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import MainPage from "./pages/Main/Main";
import DeliveryPage from "./pages/Delivery";
import CategoryPage from "./pages/Category/Category";
import TermsPage from "./pages/Terms";
import DishPage from "./pages/Dish";
import ComboPage from "./pages/Combo";
import HalfPage from "./pages/Half";
import { Header, Footer, Wrapper, Apps } from "./components";
import {
  MAIN,
  DELIVERY,
  TERMS,
  DISH,
  COMBO,
  HALF,
  CATEGORY,
} from "./constants/routers";

const Routing = () => {
  return (
    <Router>
      <Wrapper>
        <Apps />
        <Header />
        <Routes>
          <Route path={MAIN} element={<MainPage />}>
            <Route path={DISH} element={<DishPage />} />
            <Route path={COMBO} element={<ComboPage />} />
            <Route path={HALF} element={<HalfPage />} />
          </Route>
          <Route path={CATEGORY} element={<CategoryPage />} />
          <Route path={DELIVERY} element={<DeliveryPage />} />
          <Route path={TERMS} element={<TermsPage />} />
        </Routes>
        <Footer />
      </Wrapper>
    </Router>
  );
};

export default Routing;
