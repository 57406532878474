import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

// import { Loader } from "../../components";
import { getCategories } from "../../store/redux/categories";
import { getCombos } from "../../store/redux/combos";
import { getStories } from "../../store/redux/stories";
import { getSettings } from "../../store/redux/settings";
import { addOrder } from "../../store/redux/orders";
import { sortByPosition } from "../../helpers/item";
import MainView from "../Main/MainView";

const Category = ({ t }) => {
  const pathname = window.location.pathname;
  const id = pathname.slice(pathname.lastIndexOf("/") + 1);

  const dispatch = useDispatch();

  const combos = useSelector((state) => state.combos.data, shallowEqual);
  const stories = useSelector((state) => state.stories.data, shallowEqual);
  const categories = useSelector(
    (state) => state.categories.data,
    shallowEqual
  );
  const settings = useSelector((state) => state.settings.data, shallowEqual);

  const isNumeric = (value) => {
    return /^-?\d+$/.test(value);
  };

  // Remove unnecessary category
  const categoriesWithCombos = categories.filter((category) => {
    if (isNumeric(id)) {
      return category.id === parseInt(id) && category;
    } else {
      return category?.seo?.url === id && category;
    }
  });

  const nav = sortByPosition(categoriesWithCombos).map(
    (category) => category.name
  );

  useEffect(() => {
    dispatch(getStories());
    dispatch(getCombos());
    dispatch(getCategories());
    dispatch(getSettings());
  }, [t, dispatch]);

  const addDishToCart = (data) => {
    dispatch(addOrder(data));
  };

  return (
    <>
      <MainView
        stories={stories}
        combos={combos}
        categories={categoriesWithCombos}
        nav={nav}
        settings={settings}
        addDishToCart={addDishToCart}
        path={"category"}
      />
    </>
  );
};

export default withTranslation()(Category);
