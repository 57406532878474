import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { sortByPosition } from "../../../../helpers/item";
import { currentLang } from "../../../../helpers/language";
import { SERVER } from "../../../../constants/config";

import styles from "./styles.module.scss";

const Combos = ({ data, t }) => {
  const lang = currentLang();
  return (
    <section className={styles.container}>
      <h2 className={styles.title}>
        {data.length > 0 && t("pages.main.components.combo.subtitle")}
      </h2>

      <div className={styles.block}>
        <div className={styles.emptyCombo} />
        {sortByPosition(data).map((combo) => {
          return (
            <Link
              key={combo.id}
              to={{
                pathname: `${lang}/combo/${combo.id}`,
              }}
              className={styles.link}
            >
              <article
                className={styles.item}
                onMouseEnter={() => console.log()}
              >
                <img
                  className={styles.image}
                  src={SERVER + combo.image[0].url}
                />
                <div className={styles.info}>
                  <p className={styles.name}>{combo.name}</p>
                  <span>
                    {combo.price} {t("currency")}
                  </span>
                </div>
              </article>
            </Link>
          );
        })}
      </div>
    </section>
  );
};

export default withTranslation()(Combos);
