import { combineReducers } from "redux";

import stories from "./stories";
import combos from "./combos";
import categories from "./categories";
import settings from "./settings";
import dish from "./dish";
import combo from "./combo";
import category from "./category";
import iiko from "./iiko";
import orders from "./orders";

const appReducer = combineReducers({
  stories,
  combos,
  categories,
  settings,
  dish,
  combo,
  category,
  orders,
  iiko,
});

export default function (state, action) {
  return appReducer(state, action);
}
