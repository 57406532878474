import React, { useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { getCombo } from "../../store/redux/combo";
import { getCategories } from "../../store/redux/categories";
import { addOrder } from "../../store/redux/orders";
import { Drawer, Loader } from "../../components";
import { currentLang } from "../../helpers/language";
import ComboView from "./ComboView";

const Combo = () => {
  const lang = currentLang();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCombo(id));
    dispatch(getCategories());
  }, [dispatch, lang]);

  const { data, loading, error } = useSelector(
    (state) => state.combo,
    shallowEqual
  );

  const {
    data: categories,
    loading: categoriesLoading,
    error: categorieError,
  } = useSelector((state) => state.categories, shallowEqual);

  const addDishToCart = (data) => {
    dispatch(addOrder(data));
    navigate(`/${lang}/`);
  };

  if (loading || categoriesLoading) {
    return (
      <Drawer>
        <Loader />
      </Drawer>
    );
  }

  if (error || categorieError) {
    return <div>error</div>;
  }

  if (data.id && categories.length > 0) {
    return (
      <Drawer>
        <ComboView
          product={data}
          categories={categories}
          addDishToCart={addDishToCart}
        />
      </Drawer>
    );
  }
};

export default Combo;
