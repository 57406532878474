import axios from "axios";

import stories from "./stories";
import combos from "./combos";
import categories from "./catigories";
import iiko from "./iiko";
import settings from "./settings";
import dish from "./dish";
import orders from "./orders";

class Client {
  req;
  stories;
  combos;
  categories;
  iiko;
  settings;
  dish;
  order;

  constructor(baseURL) {
    this.req = axios.create({
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      baseURL,
    });

    this.stories = stories(this.req);
    this.combos = combos(this.req);
    this.categories = categories(this.req);
    this.iiko = iiko(this.req);
    this.settings = settings(this.req);
    this.dish = dish(this.req);
    this.orders = orders(this.req);
  }
}

let instance;
export default function api(baseUrl) {
  if (!instance) {
    instance = new Client(baseUrl);
  }
  return instance;
}
