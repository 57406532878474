import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { withTranslation } from "react-i18next";

import { sortByPosition } from "../../../../helpers/item";
import { currentLang } from "../../../../helpers/language";
import { SERVER } from "../../../../constants/config";
import { TYPE_DISHES } from "../../../../constants/data";
import { Badge, Icons } from "../../../../components";
import assets from "../../../../assets";

import styles from "./styles.module.scss";

const ModifiedProduct = ({ dish, t }) => {
  return (
    <>
      <span className={styles.priceHalves}>
        {dish.pizza && dish.pizza.active}
        {dish.price}
        {t("currency")}
      </span>
      <button className={styles.addToCartHalves}>
        {t("pages.main.components.categories.craft")}
      </button>
    </>
  );
};

const DefaultProduct = ({ dish, onPressBuyDish, t }) => {
  return (
    <>
      <div className={styles.purchase}>
        <span className={dish.discount_price ? styles.newPrice : styles.price}>
          {dish.discount_price && (
            <span className={styles.oldPrice}>
              {dish.pizza && dish.pizza.active}
              {dish.price}
              {t("currency")}
            </span>
          )}
          {dish.pizza && dish.pizza.active}
          {dish.discount_price ? dish.discount_price : dish.price}
          {t("currency")}
        </span>
      </div>
      <div className={styles.buttons}>
        <button
          className={
            dish.discount_price ? styles.addToNewCart : styles.addToCart
          }
        >
          {t("pages.main.components.categories.select")}
        </button>
        {!dish.dishes && (
          <button
            className={
              dish.discount_price
                ? styles.addToNewShoppingCart
                : styles.addToShoppingCart
            }
            onClick={(e) => onPressBuyDish(e, dish)}
          >
            <AiOutlineShoppingCart
              size={20}
              className={
                dish.discount_price
                  ? styles.shoppingNewCart
                  : styles.shoppingCart
              }
            />
          </button>
        )}
      </div>
    </>
  );
};

const Categories = ({ t, data, anchors, addDishToCart }) => {
  const lang = currentLang();
  const pizzas = data[0]
    ? data[0].dishes.filter((item) => {
        return item.pizza && item.pizza.active && item.pizza.halves && item;
      })
    : [];

  // Adding a static pizza halves category to the front of the category array
  let categories = JSON.parse(JSON.stringify(data));
  if (pizzas.length > 1 && !categories[0].dishes[0].modificate) {
    categories[0] &&
      categories[0].dishes.unshift({
        position: 2,
        modificate: true,
        name: t("pages.main.components.categories.title"),
        short_description: t("pages.main.components.categories.description"),
        price: "199",
        id: 0,
        pizza: {
          active: true,
        },
        icon: {
          spicy: false,
          vegetarian: false,
          very_spicy: false,
        },
      });
  }

  const onPressBuyDish = (e, dish) => {
    e.preventDefault();
    const purchaseDish = {
      id: dish.id,
      type: dish.pizza.active ? TYPE_DISHES.pizza : TYPE_DISHES.dish,
      name: dish.name,
      image: SERVER + dish.image[0].url,
      board: dish.pizza.active ? dish.pizza.boards[0].name : "",
      additives: "-",
      price: dish.discount_price ? dish.discount_price : dish.price,
      initialPrice: dish.price,
      extraCost: 0,
      count: 1,
      deleteIngredients: "-",
      description: dish.pizza.active
        ? `${dish.id} / ${
            dish.pizza.active ? dish.pizza.boards[0].name : ""
          } / - /${dish.pizza.active ? `+ ` : null}`
        : dish.short_description,
    };
    addDishToCart(purchaseDish);
  };

  const item = (dish, categoryIndex) => {
    return (
      <article className={styles.dish} key={categoryIndex}>
        <Link
          key={dish.id}
          to={{
            pathname: dish.modificate
              ? `${lang}/half`
              : dish.dishes
              ? `${lang}/combo/${dish.id}`
              : `${lang}/dish/${dish.id}`,
          }}
          className={styles.link}
        >
          <div className={styles.dishContainer}>
            <div className={styles.imageWrapper}>
              <img
                className={styles.image}
                src={
                  dish.modificate
                    ? assets.IMAGES.HALVES
                    : SERVER + dish.image[0].url
                }
                loading="lazy"
              />
              {!dish.modificate && <Badge data={dish.badge} />}
            </div>

            <div className={styles.info}>
              <div className={styles.about}>
                <p className={styles.name}>
                  {dish.name}
                  {dish.icon && <Icons data={dish} />}
                </p>
                <p className={styles.description}>{dish.short_description}</p>
              </div>

              <div className={styles.purchase}>
                {dish.modificate ? (
                  <ModifiedProduct dish={dish} t={t} />
                ) : (
                  <DefaultProduct
                    dish={dish}
                    onPressBuyDish={onPressBuyDish}
                    t={t}
                  />
                )}
              </div>
            </div>
          </div>
        </Link>
      </article>
    );
  };

  const Category = ({ data }) => {
    // const pathname = window.location.pathname;
    return (
      <>
        {/* <Link className={styles.link} to={`${pathname}category/${data.id}`}> */}
        <h3 className={styles.title}>{data.name}</h3>
        {/* </Link> */}
        <div className={styles.container}>
          <div className={styles.block}>
            {sortByPosition(data.dishes).map((dish, categoryIndex) => {
              return item(dish, categoryIndex);
            })}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={styles.wrapper}>
      {categories.length >= 1 &&
        sortByPosition(categories).map((category, index) => {
          return (
            <div key={index}>
              <div id={anchors[index]} data="nav" />
              <Category data={category} />
            </div>
          );
        })}
    </div>
  );
};

export default withTranslation()(Categories);
