import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../api";
import { TYPE_DISHES } from "../../constants/data";

const checkAvailability = (state, order) => {
  if (state.data.length > 0) {
    return state.data.some(
      (item) =>
        item.description === order.description && item.name === order.name
    );
  } else {
    return false;
  }
};

const updateQuantity = (state, order, sign) => {
  return state.data.map((item) => {
    if (item.description === order.description && item.name === order.name) {
      let count;
      if (sign && sign === "dec") {
        count = item.count - 1;
      } else {
        count = item.count + 1;
      }
      return { ...item, count };
    } else {
      return item;
    }
  });
};

export const createOrder = createAsyncThunk(
  "orders/createOrder",
  async ({ orderFormatFields }) => {
    const response = await api().orders.createOrder(orderFormatFields);
    return response.data;

    // if (orderFormatFields.online_payment === true) {
    //   const response = await api().orders.createOrderWithOnlinePayment(
    //     orderFormatFields
    //   );
    //   return response.data;
    // } else {
    //   const response = await api().orders.createOrderAndSendEmail(orderFormatFields);
    //   return response.data;
    // }
  }
);

const initialState = {
  data: [],
  orderId: null,
  loading: false,
  error: null,
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    addOrder(state, action) {
      if (checkAvailability(state, action.payload)) {
        state.data = updateQuantity(state, action.payload);
      } else {
        state.data = [...state.data, action.payload];
      }
    },
    updateOrder(state, action) {
      state.data = updateQuantity(
        state,
        action.payload.order,
        action.payload.sign
      );
    },
    cleanOrder(state) {
      state.data = [];
    },
    deleteOrder(state, action) {
      if (action.payload.type === TYPE_DISHES.dish) {
        state.data = state.data.filter((item) => {
          return item.name !== action.payload.name && item;
        });
      } else {
        state.data = state.data.filter((item) => {
          return item.description !== action.payload.description && item;
        });
      }
    },
  },

  extraReducers: {
    [createOrder.fulfilled]: (state, action) => {
      state.loading = false;
      state.id = action.payload?.order_id;
    },
    [createOrder.pending]: (state) => {
      state.loading = true;
    },
    [createOrder.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { addOrder, updateOrder, deleteOrder, cleanOrder } =
  ordersSlice.actions;
export default ordersSlice.reducer;
