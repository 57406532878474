import React, { useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { getDish } from "../../store/redux/dish";
import { addOrder } from "../../store/redux/orders";
import { Drawer, Loader } from "../../components";
import { currentLang } from "../../helpers/language";
import DishView from "./DishView";

const Dish = () => {
  const lang = currentLang();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDish(id));
  }, [dispatch]);

  const { data, loading, error } = useSelector(
    (state) => state.dish,
    shallowEqual
  );

  const addDishToCart = (data) => {
    dispatch(addOrder(data));
    navigate(`/${lang}/`);
  };

  if (loading) {
    return (
      <Drawer>
        <Loader />
      </Drawer>
    );
  }

  if (error) {
    return <div>error</div>;
  }

  if (data.id) {
    return (
      <Drawer>
        <DishView product={data} addDishToCart={addDishToCart} />
      </Drawer>
    );
  }
};

export default Dish;
