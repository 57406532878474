import React from "react";
import { Outlet } from "react-router-dom";

import { Stories, Combos, Categories, Description } from "./components";
import { Seo, Hint, Shutdown, Navigation } from "../../components";

import styles from "./styles.module.scss";

const MaiView = ({
  stories,
  combos,
  categories,
  nav,
  settings,
  addDishToCart,
  path = "main",
}) => {
  const description = categories.length === 1 && categories[0].long_description;

  return (
    <div className={styles.container}>
      <Navigation cart />
      <Seo
        path={path}
        title={categories[0]?.seo?.title}
        keywords={categories[0]?.seo?.keywords}
        description={categories[0]?.seo?.description}
      />
      <Shutdown data={settings} />
      <Stories data={stories} />
      <Combos data={combos} />
      <Navigation />
      <Categories
        data={categories}
        anchors={nav}
        addDishToCart={addDishToCart}
      />
      <Description text={description} />
      <Hint />
      <Outlet />
    </div>
  );
};

export default MaiView;
