import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../api";

export const getCategories = createAsyncThunk(
  "categories/getCategories",
  async () => {
    const response = await api().categories.getCategories();
    return response.data;
  }
);

export const categoriesSlice = createSlice({
  name: "categories",
  initialState: { data: [], loading: false, error: null },
  reducers: {},
  extraReducers: {
    [getCategories.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [getCategories.pending]: (state) => {
      state.loading = true;
    },
    [getCategories.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default categoriesSlice.reducer;
