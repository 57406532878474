import _ from "lodash";

import { TYPE_DISHES, TYPE_DELIVERY } from "../constants/data";

const type = TYPE_DISHES.pizza;

//
// ----- This function calculates the bonus 50%
//
export const calcBonus = (cost, userBonus) => {
  let newCost = 0;
  const max = (cost / 100) * 50;
  if (userBonus.balance >= max) {
    newCost = cost - max;
  } else {
    newCost = userBonus.balance;
  }
  return newCost;
};

//
// ----- This function calculates the discount for promo 1, promotion 1+1=3
//
export const calcProm1 = (orders, active) => {
  if (active) {
    const listOrderOnlyPizzas = orders.filter((item) => item.type === type);
    let priceList = [];
    listOrderOnlyPizzas.forEach((element) => {
      if (element.count > 1) {
        for (let i = 0; i < element.count; i++) {
          priceList.push(element.initialPrice);
        }
      } else {
        priceList.push(element.initialPrice);
      }
    });
    const quantityDiscountItem = Math.floor(priceList.length / 3);

    const sortedPriceList1 = priceList
      .sort((a, b) => a - b)
      .slice(0, quantityDiscountItem * 3);
    const sortedPriceList2 = priceList
      .sort((a, b) => a - b)
      .slice(0, quantityDiscountItem);

    return {
      sum: _.sum(sortedPriceList1) > 0 ? _.sum(sortedPriceList1) : 0,
      sale: _.sum(sortedPriceList2) > 0 ? _.sum(sortedPriceList2) : 0,
    };
  }
  return { sum: 0, sale: 0 };
};

//
// ----- This function calculates the price of each item
//
export const curentProductPrice = (dish, delivery, orders, settings) => {
  const activeProm1 = calcProm1(orders, settings?.promotion1).sale > 0;
  let pizzaList = [];
  orders.forEach((order) => {
    if (order.type === TYPE_DISHES.pizza) {
      for (let i = 0; i < order.count; i++) {
        pizzaList.push(order);
      }
    }
  });
  let validPizzaList = pizzaList.sort(
    (a, b) => a.initialPrice - b.initialPrice
  );
  const quantityDiscontSets = Math.trunc(validPizzaList.length / 3);
  validPizzaList = validPizzaList.slice(quantityDiscontSets * 3);

  // const activePromo = pizzaList.length === (quantityDiscontSets % 3) * 3;
  // console.log("activePromo", activePromo);
  // const validDish = validPizzaList.some((item) => {
  //   return activePromo && dish.price > item;
  // });
  // console.log(validDish);

  if (delivery === TYPE_DELIVERY[0]) {
    if (activeProm1 && dish.type === TYPE_DISHES.pizza) {
      if (dish.extraCost && dish.extraCost > 0) {
        return dish.initialPrice + dish.extraCost;
      }
      if (validPizzaList.length > 0) {
        const validDish = validPizzaList.some((valiDish) => {
          return dish.id === valiDish.id;
        });
        return !validDish ? dish.price : dish.initialPrice;
      }
      return dish.initialPrice ? dish.initialPrice : dish.price;
    }

    return dish.price;
  }

  if (delivery === TYPE_DELIVERY[1]) {
    if (dish.type === TYPE_DISHES.pizza) {
      if (dish.initialPrice > dish.price) {
        return dish.initialPrice + dish.extraCost;
      } else {
        if (dish.extraCost && dish.extraCost > 0) {
          return dish.initialPrice + dish.extraCost;
        }
        return dish.price;
      }
    } else {
      return dish.initialPrice;
    }
  }
};

//
// This function calculates the total cost
//
export const totalProductsCost = (orders, delivery, settings) => {
  const flatArrayDishes = [];
  orders.forEach((order) => {
    for (let i = 0; i < order.count; i++) {
      flatArrayDishes.push(order);
    }
  });

  const allPrices = flatArrayDishes.map((dish) => {
    return curentProductPrice(dish, delivery, orders, settings);
  });
  return _.sum(allPrices);
};

//
// ----- This function calculates the total sale
//
export const calcSaleAmount = (
  orders,
  delivery,
  settings,
  useBonus,
  promo,
  iiko
) => {
  const {
    discount_for_pickup,
    promo_active,
    promo_start,
    promo_text,
    promo_discount,
    actiive_bonus,
    promotion1,
  } = settings;
  const { sale } = calcProm1(orders, promotion1);

  const ordersOnlyCombo = _.sum(
    orders
      .filter((order) => order.type === TYPE_DISHES.combo)
      .map((item) => item.price)
  );

  const totalCost =
    totalProductsCost(orders, delivery, settings) - ordersOnlyCombo;

  let totalSale = 0;

  if (sale && delivery === TYPE_DELIVERY[0]) {
    totalSale = totalSale + sale;
  }

  if (discount_for_pickup && delivery === TYPE_DELIVERY[1]) {
    const discount = (totalCost / 100) * 10;
    totalSale = totalSale + discount;
  }

  if (
    promo_active &&
    promo_text === promo &&
    totalCost > promo_start &&
    !sale
  ) {
    totalSale = totalSale + promo_discount;
  }

  if (actiive_bonus && useBonus) {
    console.log("1", calcBonus(totalCost, iiko), totalCost, iiko);
    totalSale = totalSale + calcBonus(totalCost, iiko);
  }

  return Math.ceil(totalSale);
};

//
// ----- This function calculates the total cost
//
export const calcSumAmount = (
  orders,
  delivery,
  settings,
  useBonus,
  promo,
  iiko
) => {
  const { free_delivery, cost_delivery } = settings;
  let totalAmount = 0;

  if (delivery === TYPE_DELIVERY[0]) {
    totalAmount =
      totalProductsCost(orders, delivery, settings) -
      calcSaleAmount(orders, delivery, settings, useBonus, promo, iiko);

    if (totalAmount < free_delivery && !useBonus) {
      console.log(2, totalAmount, free_delivery);
      totalAmount = totalAmount + cost_delivery;
    }
  }
  if (delivery === TYPE_DELIVERY[1]) {
    totalAmount =
      totalProductsCost(orders, delivery, settings) -
      calcSaleAmount(orders, delivery, settings, useBonus, promo, iiko);
  }
  return Math.ceil(totalAmount);
};
