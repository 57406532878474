import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { BsCartCheckFill } from "react-icons/bs";
import { FaMoneyCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { List, Bonus, Delivery, Payment, LiqPayPay, Promo } from "./components";
import { SideBar, Loader } from "../../components";

import {
  calcBonus,
  calcSumAmount,
  calcSaleAmount,
  totalProductsCost,
} from "../../helpers/calculator";

import { emailTemplate } from "../../helpers/email";
import { TYPE_DELIVERY } from "../../constants/data";

import styles from "./styles.module.scss";
import assets from "../../assets";

const PHONE_LENGTH = 19;

const CartView = ({
  t,
  data,
  settings,
  iiko,
  categories,
  getIikoBonus,
  createNewOrder,
  visibleCart,
  isVisibleCart,
  cleanOrder,
}) => {
  const [orderId, setOrderId] = useState(0);
  const [phone1, setPhone1] = useState("+{38");
  const [phone2, setPhone2] = useState("+{38");
  const [name, setName] = useState("");
  const [addressDelivery, setAddressDelivery] = useState("");
  const [addressPickUp, setAddressPickUp] = useState(
    t("pages.cart.components.delivery.address1")
  );
  const [bonus, setBonus] = useState(false);
  const [delivery, setDelivery] = useState(TYPE_DELIVERY[0]);
  const [time, setTime] = useState("now");
  const [comment, setComment] = useState("");
  const [isCutlery, setIsCutlery] = useState(1);
  const [payment, setPayment] = useState("");
  const [promo, setPromo] = useState("");
  const { data: orders, id, loading, error } = data;

  // const [finalDiscount, setFinalDiscount] = useState(0);
  // const [finalPrice, setFinalPrice] = useState(0);

  useEffect(() => {
    const addressDelivery = localStorage.getItem("addressDelivery");
    addressDelivery && setAddressDelivery(addressDelivery);
  }, []);

  useEffect(() => {
    const orderId = Math.floor(Math.random() * (10000 - 1000 + 1) + 1000);
    setOrderId(orderId);
  }, []);

  useEffect(() => {}, []);

  const { search } = useLocation();
  const navigate = useNavigate();

  const getIikoBonuses = (phone) => {
    setPhone1(phone);
    if (phone.length === PHONE_LENGTH) {
      getIikoBonus(phone);
    }
  };

  let paymentArray = [
    { name: "paymentByCash", on: settings.payment_by_cash },
    { name: "paymentByCart", on: settings.payment_by_cart },
    { name: "paymentByOnline", on: settings.payment_by_online_web },
  ];
  paymentArray = paymentArray.filter((item) => item.on && item);

  const useBonus = bonus && phone1.length === PHONE_LENGTH;

  const calcSum = () => {
    return calcSumAmount(orders, delivery, settings, useBonus, promo, iiko);
  };

  const calcSale = () => {
    return calcSaleAmount(orders, delivery, settings, useBonus, promo, iiko);
  };

  const validFields = () => {
    const validName = name.length > 0;
    const phone = phone1.length >= PHONE_LENGTH ? phone1 : phone2;
    const validPhone = phone.length >= PHONE_LENGTH;
    const place =
      delivery === TYPE_DELIVERY[0] ? addressDelivery : addressPickUp;
    const validAddress = place && place.length > 0;
    const validPayment = payment && payment.length > 0;
    return validName && validPhone && validAddress && validPayment;
  };
  const curentBonus =
    iiko && useBonus
      ? Math.ceil(
          calcBonus(totalProductsCost(orders, delivery, settings), iiko)
        )
      : 0;

  const createOrder = () => {
    const orderFormatFields = emailTemplate({
      orderId,
      name,
      phone1,
      phone2,
      addressDelivery,
      addressPickUp,
      orders,
      bonus: curentBonus,
      iiko,
      cost: calcSum(),
      deliveryMethod: delivery,
      delivery: t(`pages.cart.components.delivery.${delivery}`),
      deliveryPrice:
        calcSum() > settings.free_delivery ? "0" : settings.cost_delivery,
      settings,
      payment: t(`pages.cart.components.payment.${payment}`),
      online_payment: payment,
      comment,
      time,
      isCutlery,
    });

    const items = orders.map((order) => {
      return { item_id: order.id, item_name: order.name };
    });
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "purchase",
      ecommerce: {
        transaction_id: orderId,
        value: calcSum(),
        currency: "UAH",
        items: items,
      },
    });

    createNewOrder(orderFormatFields);
    if (payment === "paymentByOnline") {
      navigate("?payment=true");
    } else {
      cleanOrder();
      navigate("?order=true");
    }
  };

  if (search === "?order=true" && error) {
    return (
      <SideBar visibleCart={visibleCart} isVisibleCart={isVisibleCart}>
        <h2 className={styles.title}>{t("pages.cart.title")}</h2>
        <p className={styles.doneTitle}>Error {error}</p>
      </SideBar>
    );
  }

  if (search === "?order=true" && loading) {
    return (
      <SideBar visibleCart={visibleCart} isVisibleCart={isVisibleCart}>
        <h2 className={styles.title}>{t("pages.cart.title")}</h2>
        <Loader />
      </SideBar>
    );
  }

  if (search === "?order=true") {
    return (
      <SideBar visibleCart={visibleCart} isVisibleCart={isVisibleCart}>
        <h2 className={styles.title}>{t("pages.cart.title")}</h2>
        <div className={styles.empty}>
          <BsCartCheckFill className={styles.cartDone} />
          <p className={styles.doneTitle}> {t("pages.cart.doneTitle")}</p>
          <p className={styles.doneText}> {t("pages.cart.doneText")}</p>
          <p className={styles.doneText}>
            {t("pages.cart.doneText2")}
            {orderId}
          </p>
        </div>
      </SideBar>
    );
  }

  if (search === "?payment=true") {
    return (
      <SideBar visibleCart={visibleCart} isVisibleCart={isVisibleCart}>
        <h2 className={styles.title}>{t("pages.cart.title")}</h2>
        <div className={styles.empty}>
          <FaMoneyCheck className={styles.cartDone} />
          <p className={styles.doneTitle}> {t("pages.cart.doneTitle")}</p>
          <p className={styles.donePaymentText}>
            {t("pages.cart.donePaymentText")}
          </p>
          <p className={styles.doneText}>
            {t("pages.cart.doneText2")}
            {orderId}
          </p>
        </div>
        <div className={styles.payment}>
          <LiqPayPay amount={calcSum()} id={id} cleanOrder={cleanOrder} />
        </div>
      </SideBar>
    );
  }

  if (orders.length === 0) {
    return (
      <SideBar visibleCart={visibleCart} isVisibleCart={isVisibleCart}>
        <h2 className={styles.title}>{t("pages.cart.title")}</h2>
        <div className={styles.empty}>
          <img className={styles.emptyCart} src={assets.IMAGES.EMPTY_CART} />
          <p className={styles.emptyText}> {t("pages.cart.emptyText")}</p>
        </div>
      </SideBar>
    );
  }

  return (
    <SideBar visibleCart={visibleCart} isVisibleCart={isVisibleCart}>
      <div className={styles.container}>
        <h2 className={styles.title}>{t("pages.cart.title")}</h2>
        <List
          t={t}
          orders={orders}
          categories={categories}
          delivery={delivery}
          settings={settings}
        />
        <Bonus
          t={t}
          phone={phone1}
          getIikoBonuses={getIikoBonuses}
          iiko={iiko}
          setBonus={setBonus}
        />
        {settings.promo_active && (
          <Promo
            t={t}
            promo={promo}
            setPromo={setPromo}
            promoStart={settings.promo_start}
          />
        )}
        <Delivery
          t={t}
          delivery={delivery}
          setDelivery={setDelivery}
          phone1={phone1}
          phone2={phone2}
          setPhone2={setPhone2}
          name={name}
          setName={setName}
          address={addressDelivery}
          setAddress={setAddressDelivery}
          setAddressPickUp={setAddressPickUp}
          time={time}
          setTime={setTime}
          comment={comment}
          setComment={setComment}
          isCutlery={isCutlery}
          setIsCutlery={setIsCutlery}
        />
        <Payment
          t={t}
          paymentArray={paymentArray}
          payment={payment}
          setPayment={setPayment}
        />
        <div className={styles.block}>
          <div className={styles.delivery}>
            <span>
              {t("pages.cart.delivery")}
              <br />
              {settings.free_delivery !== 10000 && (
                <span className={styles.freeDelivery}>
                  {t("pages.cart.freeDelivery")}
                  {settings && settings.free_delivery + 1}
                </span>
              )}
            </span>
            {settings.free_delivery && settings.cost_delivery && (
              <span>{`${
                delivery === TYPE_DELIVERY[0]
                  ? totalProductsCost(orders, delivery, settings) >
                    settings.free_delivery
                    ? "0"
                    : settings.cost_delivery
                  : "0"
              }${t("currency")}`}</span>
            )}
          </div>
          <div className={styles.discount}>
            <span>{t("pages.cart.discount")}</span>
            <span>{calcSale()}</span>
          </div>
          <div className={styles.delivery}>
            <span>{t("pages.cart.total")}</span>
            <span>
              {calcSum()}
              {t("currency")}
            </span>
          </div>
          <button
            id={"createOrder"}
            className={validFields() ? styles.button : styles.buttonNotActive}
            onClick={validFields() && createOrder}
          >
            {t("pages.cart.checkoutButton")}
            {calcSum()}
            {t("currency")}
          </button>
        </div>
      </div>
    </SideBar>
  );
};

export default withTranslation()(CartView);
