import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { HiCheckCircle } from "react-icons/hi";

import { Toggle, NutValue, Seo, Badge } from "../../components";
import { TYPE_DISHES } from "../../constants/data";
import { sortByPosition } from "../../helpers/item";
import { SERVER } from "../../constants/config";

import styles from "./styles.module.scss";

const Dish = ({ t, product, addDishToCart }) => {
  const [ingredients, setIngredients] = useState(product.pizza.ingredient);
  const [additives, setAdditives] = useState(product.pizza.additives);
  const [board, setBoard] = useState([product.pizza.boards[0]?.name]);
  const boards = product.pizza.boards.map((item) => item.name);

  const Boards = () => {
    return (
      <>
        <h3 className={styles.ingredientsTitle}>{t("pages.dish.side")}</h3>
        <Toggle
          items={boards}
          activeItem={board}
          setActiveItem={setBoard}
          activeIndex={0}
        />
      </>
    );
  };

  const Additives = () => {
    const updateAdditives = (additive) => {
      const newAdditives = additives.map((item) => {
        return item.name === additive.name
          ? { ...item, active: !item.active }
          : item;
      });
      setAdditives(newAdditives);
    };
    return (
      <>
        <h3 className={styles.ingredientsTitle}>{t("pages.dish.add")}</h3>
        <ul className={styles.addIngrediets}>
          {sortByPosition(additives).map((item, index) => {
            return (
              <li
                key={index}
                onClick={() => updateAdditives(item)}
                className={item.active && styles.addCurrent}
              >
                {item.active && <HiCheckCircle className={styles.addCheck} />}
                <img
                  className={styles.addImage}
                  src={SERVER + item.image[0].url}
                />
                <div className={styles.addInfo}>
                  <span className={styles.addTitle}>{item.name}</span>
                  <span className={styles.addPrice}>
                    {item.price} {t("currency")}
                  </span>
                </div>
              </li>
            );
          })}
        </ul>
      </>
    );
  };

  const Ingredients = () => {
    const updateIngredient = (id) => {
      const newIngredients = ingredients.map((ingredient) => {
        if (ingredient.id === id) {
          return { ...ingredient, delete: !ingredient.delete };
        }
        return ingredient;
      });
      setIngredients(newIngredients);
    };
    return (
      <>
        <h3 className={styles.ingredientsTitle}>
          {t("pages.dish.ingredientsDisplay")}
        </h3>
        <p className={styles.ingredients}>
          {ingredients &&
            ingredients.map((ingredient, index) => {
              if (ingredient.edit) {
                return (
                  <span
                    onClick={() => updateIngredient(ingredient.id)}
                    key={index}
                    className={[
                      ingredient.delete
                        ? styles.deleteIngredient
                        : styles.ingredient,
                    ]}
                  >
                    {ingredient.name}
                  </span>
                );
              } else {
                return (
                  <span key={index} className={styles.defaultIngredient}>
                    {ingredient.name}
                  </span>
                );
              }
            })}
        </p>
      </>
    );
  };

  const calculateCost = () => {
    let price = product.discount_price ? product.discount_price : product.price;
    boards.length > 0 &&
      product.pizza.boards.forEach((item) => {
        if (item.name === board[0]) {
          price = price + item.price;
        }
      });
    additives &&
      additives.forEach((item) => {
        if (item.active) {
          price = price + item.price;
        }
      });
    return price;
  };

  const onPressBuyDish = () => {
    const active = product.pizza.active;
    const activeAdditives = active
      ? additives.filter((item) => item.active)
      : null;

    const additivesString = active
      ? activeAdditives.map((item) => item.name).join(", ")
      : null;

    const deleteIngredients = active
      ? ingredients.filter((item) => item.delete && item.name)
      : null;

    const ingredientsString = active
      ? deleteIngredients.map((item) => item.name).join(", ")
      : null;
    const image =
      boards.length > 0
        ? boards[0] === String(board)
          ? SERVER + product.image[0].url
          : SERVER + product.image[1].url
        : SERVER + product.image[0].url;
    const purchaseDish = {
      id: product.id,
      type: active ? TYPE_DISHES.pizza : TYPE_DISHES.dish,
      name: product.name,
      image: image,
      board: boards.length > 0 ? board : "",
      addedBoard: board !== boards[0],
      additives: active
        ? `${additivesString.length > 0 ? "+" : ""} ${additivesString}`
        : "-",
      price: calculateCost(),
      extraCost:
        calculateCost() -
        (product.discount_price ? product.discount_price : product.price),
      initialPrice: product.price,
      count: 1,
      deleteIngredients: `${
        ingredientsString && ingredientsString.length > 0 ? "-" : ""
      } ${ingredientsString}`,
      description: active
        ? `${product.id} / ${board} / - ${ingredientsString}/${
            active ? `+ ${additivesString}` : null
          }`
        : product.short_description,
    };
    addDishToCart(purchaseDish);
  };

  const pizzaActive = product.pizza.active;

  return (
    <div className={styles.container}>
      <div className={styles.leftBlock}>
        <Seo
          t={t}
          path="dish"
          title={product.seo.title}
          keywords={product.seo.keywords}
          description={product.seo.description}
        />
        <div className={styles.dish}>
          {product.image && (
            <img
              className={styles.image}
              src={
                pizzaActive
                  ? !boards.length > 0
                    ? SERVER + product.image[0].url
                    : boards[0] === board[0]
                    ? SERVER + product.image[0].url
                    : SERVER + product.image[1].url
                  : SERVER + product.image[0].url
              }
            />
          )}
          <Badge data={product.badge} />
        </div>
      </div>
      <div className={styles.rightBlock}>
        <div
          className={
            product.category != 1 ? styles.coverPercent : styles.coverContent
          }
          id="cov"
        >
          <div className={styles.infoBlock}>
            <p className={styles.title}>
              <span>{product.name}</span>
              <NutValue product={[product]} />
            </p>
            <p className={styles.parameters}>
              {product.weight}
              {product.unit === "grams"
                ? t("pages.dish.grams")
                : t("pages.dish.liters")}
            </p>
            <p className={styles.description}>{product.short_description}</p>
            {pizzaActive && <Ingredients />}
            {pizzaActive && boards.length > 0 && <Boards />}
            {pizzaActive && additives.length > 0 && <Additives />}
          </div>
          <div className={styles.longDescription}>
            {product.long_description}
          </div>
        </div>

        <div className={styles.footer}>
          <button
            className={styles.addtoCart}
            type="button"
            onClick={onPressBuyDish}
          >
            {`${t("pages.dish.addToCart")} ${calculateCost()} ${t("currency")}`}
          </button>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Dish);
