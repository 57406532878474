import React from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";

import styles from "./styles.module.scss";

const Seo = ({ t, path, title, keywords, description }) => {
  if (
    path === "dish" ||
    path === "combo" ||
    path === "half" ||
    path === "category"
  ) {
    return (
      <Helmet className={styles.container}>
        <meta charSet={t(`charSet`)} />
        <title>{title}</title>
        <meta name="keywords" content={keywords} />
        <meta name="description" content={description} />
      </Helmet>
    );
  } else {
    return (
      <Helmet className={styles.container}>
        <meta charSet={t(`charSet`)} />
        <title>{t(`seo.${path}.title`)}</title>
        <link rel="canonical" href={t(`seo.${path}.page`)} />
        <meta name="keywords" content={t(`seo.${path}.keywords`)} />
        <meta name="description" content={t(`seo.${path}.description`)} />
      </Helmet>
    );
  }
};

export default withTranslation()(Seo);
