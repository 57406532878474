import React from "react";
import { withTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

import { currentLang } from "../../helpers/language";

import styles from "./styles.module.scss";

const Language = ({ i18n }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const languages = Object.keys(i18n.options.resources);
  const currentLanguage = currentLang();

  const setLang = (lang) => {
    const formatLang = lang === "UA" ? "uk" : lang.toLowerCase();
    navigate(`/${formatLang}${pathname.slice(3)}`);
    i18n.changeLanguage(formatLang);
  };
  const test =
    currentLanguage === "uk" ? "UA" : currentLanguage.toLocaleUpperCase();

  return (
    <div className={styles.container}>
      <select
        className={styles.select}
        onChange={(e) => setLang(e.target.value)}
      >
        {Object.values(i18n.options.resources).map((lang, index) => {
          return (
            <option
              key={index}
              value={lang.translation.name}
              selected={test === lang.translation.name}
            >
              {lang.translation.name}
            </option>
          );
        })}
      </select>

      <div className={styles.list}>
        {Object.values(i18n.options.resources).map((lang, index) => {
          return (
            <div
              className={styles.item}
              onClick={() => setLang(languages[index])}
              key={index}
            >
              <span
                className={
                  languages[index] === currentLanguage
                    ? styles.activeLanguage
                    : styles.language
                }
              >
                {lang.translation.name}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default withTranslation()(Language);
