import API from "./base/api";
import { SERVER } from "../constants/config";
import { currentLang } from "../helpers/language";

class DishApi extends API {
  async getDish(id) {
    const lang = currentLang();
    return this.r({
      method: "GET",
      url: `${SERVER}/dishes/${id}?_locale=${lang}`,
    });
  }
}

export default function dishApi(request) {
  return new DishApi(request);
}
